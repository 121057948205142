import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SHA256 } from 'crypto-js';

type Valuable<T> = { [K in keyof T as T[K] extends null | undefined ? never : K]: T[K] };

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  router = inject(Router);

  makeQueryStringFromDictionary(params: any) {
    let output = null;
    if (params && typeof params !== 'undefined') {
      output = '?';
      for (const key of Object.keys(params)) {
        output += key + '=' + params[key] + '&';
      }
    }
    return output;
  }

  hashString(str: string): string {
    return SHA256(str).toString();
  }

  capitalize(value: string): string {
    return value[0].toUpperCase() + value.slice(1);
  }

  static cleanObject<T extends {}, V = Valuable<T>>(obj: T): V {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([, v]) =>
          !((typeof v === 'string' && !v.length) || v === null || typeof v === 'undefined'),
      ),
    ) as V;
  }

  generateMediaLink(path: string): string {
    return `https://media.thumbzone.ai/${path}`;
  }
}
